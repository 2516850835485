import { Badge, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import ManageNotificationModal from "./ManageNotificationModal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { NotificationSubscription } from "../models/NotificationPreference";
import { ProductFilterOptions } from "../models/Items/FilterOptions";
import api from "../services/ApiService";
import { ApiResponse } from "../models/ApiResponse";
import SessionService from "../services/SessionService";


const ManageNotification = () => {

  const initialValues: NotificationSubscription = {
    id: 0,
    brandCollections: [],
    marketingDesigners: [],
    seriesNames: [],
    categories: [],
    slIntroDates: [],
    notificationPreferences: []
  };

  const [manageNotificationModal, setManageNotificationModal] = useState(false);
  const [notificationSubscription, setNotificationSubscription] = useState<NotificationSubscription>(initialValues);
  const [filterOptions, setFilterOptions] = useState<ProductFilterOptions>({});
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(null);

  const sessionService = SessionService.getInstance();

  useEffect(() => {
    fetchFilterOptions();
    fetchNotificationSubscriptionData();
  }, []);

  /* 
    Runs when `notificationSubscription` or `filterOptions` changes.
    Maps notification data to filter options and updates the `selectedFilterOptions` state.
   */
  useEffect(() => {
    if ((notificationSubscription && Object.keys(notificationSubscription).length > 0) &&
      (filterOptions && Object.keys(filterOptions).length > 0)) {

      const { seriesNames, slIntroDates } = notificationSubscription;
      const getFilterSelection = mapNotificationToFilterOptions();

      setSelectedFilterOptions({
        ...getFilterSelection,
        seriesNames,
        slIntroDates
      });
    }
  }, [notificationSubscription, filterOptions]);

  /* 
    Maps notificationSubscription IDs to filter options by matching them with the corresponding filter options.
    Returns an object with selected filter options based on FIELD_MAPPING.
   */
  const mapNotificationToFilterOptions = () => {
    const FIELD_MAPPING = {
      marketingDesigners: "productDesignerMkts",
      categories: "productcategories",
      brandCollections: "brands",
    };

    const prepareSelectedFilterOptions = {};

    Object.entries(FIELD_MAPPING).forEach(([notificationKey, filterOptionKey]) => {
      // Check if notificationSubscription contains the id array for the notificationKey
      if (notificationSubscription[notificationKey] && Array.isArray(notificationSubscription[notificationKey])) {
        prepareSelectedFilterOptions[notificationKey] = notificationSubscription[notificationKey]
          .map(id => filterOptions[filterOptionKey]?.find(option => option.id === id))
      }
    });

    return prepareSelectedFilterOptions;
  };

  /* 
   Fetches notification subscription data from the API and updates the `notificationSubscription` state.
   */
  const fetchNotificationSubscriptionData = async () => {
    const sessionToken = sessionService.getSessionToken();

    const response = await fetch(`${process.env.REACT_APP_API_URL}/Notification/subscription`, {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + sessionToken
      }
    });

    if (response.ok) {
      const responseData = await response.json();
      const notificationSubscriptionResponse: NotificationSubscription = responseData?.data || [];
      setNotificationSubscription(notificationSubscriptionResponse);
      formik.setValues({
        ...notificationSubscriptionResponse
      });
    };
  };

  /* 
    Fetches filter options and set it to the state
   */
  const fetchFilterOptions = async () => {
    try {
      const dropDownSources = await api.get<ApiResponse<ProductFilterOptions>>(`/Item/filter-options`);
      setFilterOptions(dropDownSources.data);
    } catch (error) {
      console.error("Exception from filter options", error);
    }
  };

  const handleManageNotificationModal = async () => {
    await fetchNotificationSubscriptionData();
    setManageNotificationModal(true);
  };

  const resetAllStates = () => {
    setManageNotificationModal(false);
    setSelectedFilterOptions(null);
    setNotificationSubscription(initialValues);
  };

  const closeManageNotificationModal = () => {
    resetAllStates();
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (formValues) => {
      try {
        const sessionToken = sessionService.getSessionToken();

        const response = await fetch(`${process.env.REACT_APP_API_URL}/Notification/subscription`, {
          method: 'PUT',
          headers: {
            "Authorization": "Bearer " + sessionToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formValues)
        });

        if (response.ok) {
          const updateSubscriptionResToJson = await response.json();
          if(updateSubscriptionResToJson?.isSuccess) {
            toast.success(updateSubscriptionResToJson?.message);
            setManageNotificationModal(false);
            await fetchNotificationSubscriptionData();
          }
        };
      } catch (error) {
        setManageNotificationModal(false);
        console.log("Error in Updating Notification Subscription", error);
      }
    }
  });

  return (
    <>
      {manageNotificationModal &&
        <ManageNotificationModal
          isOpen={manageNotificationModal}
          onClose={closeManageNotificationModal}
          formik={formik}
          filterOptions={filterOptions}
          selectedFilterOptions={selectedFilterOptions}
          setSelectedFilterOptions={setSelectedFilterOptions}
        />}

      <IconButton
        id="basic-button"
        title="Manage Notifications Subscription"
        aria-haspopup="true"
        onClick={handleManageNotificationModal}>
        <Badge>
          <EditNotificationsIcon sx={{ color: "#484848" }} />
        </Badge>
      </IconButton>
    </>
  );
};

export default ManageNotification;