import React, { useEffect, useState } from 'react'
import Filters from "../Products/Filters";
import ProductCard from "../../components/ProductCard";
import { Box, Typography } from "@mui/material";
import Pagination from "../../components/Pagination";
import { SelectedFilters } from "../../models/Items/ItemListRequest";
import { ProductListResponse } from "../Products/ProductList";
import { ApiResponse } from "../../models/ApiResponse";
import { ItemBasicDetail } from "../../models/Items/ItemBasicDetail";
import { Permissions, PermissionsFriendlyNames, SortDirection } from "../../models/Enum";
import { useAppDispatch, useAppSelector } from "../../store";
import api from "../../services/ApiService";
import Loader from "../../components/Loader";
import { updateDashboardListRequest } from "../../reducers/itemsReducer";

const ExecutiveDashboard = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);
  const [items, setItems] = useState<ItemBasicDetail[]>([]);
  const [productsTotalCount, setProductsTotalCount] = useState(0);
  
  const dispatch = useAppDispatch();
  
  const dashboardFilterValuesSelected = useAppSelector((state) => state.filters.dashboardView.selectedFilterValues);
  const dashboardListRequest = useAppSelector((state) => state.items.dashboardListRequest);
  const dashboardExecutiveReviewTagId = useAppSelector((state) => state.filters.dashboardView.reviewRequiredTagId);

  const rowsPerPageOptions = [8, 20, 50, 100];
  const [page, setPage] = useState(dashboardListRequest.pageIndex - 1);  
  const [pageSize, setPageSize] = useState(rowsPerPageOptions[0]);

  const initialItemListRequest = {
    pageIndex: 1,
    pageSize: pageSize,
    sortBy: "id",
    sortDirection: SortDirection.DESC,
    tags: [dashboardExecutiveReviewTagId],
  };

  /**
   * This useEffect is used to fetch product list and set it to state
   */
  useEffect(() => {
    const getItems = async () => {
      try {
        const productListRequest = { ...dashboardListRequest, ...dashboardFilterValuesSelected };
        setIsLoading(true);
        api
          .post<ApiResponse<ProductListResponse>>("/item/list", productListRequest)
          .then((response) => {
            setIsLoading(false);
            if (response.isSuccess) {
              setProductsTotalCount(response?.data?.totalCount);
              const items: ItemBasicDetail[] = (response?.data?.items || []);
              setItems(items);
            } else {
              throw new Error(response.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log("Exception from item list", error);
          });
      } catch (error: any) {
        setIsLoading(false);
        console.log("Exception from item list", error);
      }
    };
    if(dashboardExecutiveReviewTagId) getItems();
  }, [dashboardListRequest]);

  /**
   * This function is used to handle apply filter and set selected filter in product list request state
   */
  const handleApplyFilterClick = (filterValues: SelectedFilters) => {
    if (filterValues.projectCode) {
      dispatch(updateDashboardListRequest({
        ...dashboardListRequest,
        projectCode: filterValues.projectCode,
        pageIndex: 1,
      }));
    } else if (Object.values(filterValues)?.length) {
      dispatch(updateDashboardListRequest({
       ...dashboardListRequest,
        ...dashboardFilterValuesSelected,
        pageIndex: 1,
      })); 
    } else {
      //reset the filters
      setPage(0);
      setPageSize(rowsPerPageOptions[0]);
      dispatch(updateDashboardListRequest({
        ...initialItemListRequest,
         pageSize: rowsPerPageOptions[0]
       }));
    }
  };

  const handleExpandClick = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  /**
   * This useEffect is used to handle page change of pagination
   * @param value - current page number
   */
  const handlePageChange = (event: any, value: number) => {
    setPage(value - 1);
    dispatch(updateDashboardListRequest({
      ...dashboardListRequest,
      pageIndex: value,
    }));
  };

  /**
   * This useEffect is used to handle rows per page change of pagination
   * @param event - event object that contains new selected rows per page value
   */
  const handleRowsPerPageChange = (event: any) => {
    setPageSize(parseInt(event.target.value as string, 10));
    dispatch(updateDashboardListRequest({
      ...dashboardListRequest,
      pageIndex: 1,
      pageSize: Number(event.target.value)
    }));
  };

  /**
   * This useEffect is used to handle notes field value change
   * @param value - notes field value
   * @param index - product array index
   */
  const handleNotesChange = (value: string, arrayIndex: number) => {
    let setNotesValue = items.map((product, index) => {
      if (index === arrayIndex) {
        return { ...product, notes: value };
      }
      return product;
    });
    setItems(setNotesValue);
  };

  /**
   * This useEffect is used to handle approve / reject button and submit to api
   * @param approveOrReject - Approve or Reject button click
   * @param index - product array index
   */
  const handleSubmit = (approveOrReject: string, index: number) => {
    const { id, notes } = items[index];
    const executiveReviewRequest = {
      isApprove: !!(approveOrReject === "Approve"),
      notes: notes
    };

    api
    .post<ApiResponse<ProductListResponse>>(`/item/executive-review/${id}`, executiveReviewRequest)
    .then((response) => {
        setIsLoading(false);
        if (response?.isSuccess) {
          setItems([]);
          dispatch(updateDashboardListRequest({
            ...dashboardListRequest,
          }));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Exception from executive review", error);
      });
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Filters
        onApplyFilter={handleApplyFilterClick}
        filtersExpanded={filtersExpanded}
        handleExpandClick={handleExpandClick}
        viewMode={PermissionsFriendlyNames[Permissions.ExecutiveDashboard]}
      />
      <div className="product-container">
        {items?.length > 0 &&
          items.map((product, index) => (
            <ProductCard
              key={product.id}
              product={product}
              onAddNoteChange={(value) => handleNotesChange(value, index)}
              onSubmit={(approveOrReject) => handleSubmit(approveOrReject, index)}
            />
          ))}
      </div>
      {productsTotalCount === 0 &&
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          marginTop="40px"
          marginBottom="10px"
        >
          <Typography variant="h5" component="div" fontWeight="bold">
            No records found
          </Typography>
        </Box>}
      {productsTotalCount > 0 &&
        <Pagination
          pageCount={Math.ceil(productsTotalCount / pageSize)}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          totalRows={productsTotalCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />}
    </>
  )
}

export default ExecutiveDashboard;