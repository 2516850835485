import {
  Autocomplete, Box, Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DATE_FORMAT, NOTIFICATION_TYPE_FRIENDLY_NAME } from "../utils/constants";
import { FIELD_NAMES } from "../pages/Products/EditItem/Constants";
import moment from "moment";
import { useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { ProductFilterOptions } from "../models/Items/FilterOptions";

interface ManageNotificationModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  formik: any;
  filterOptions: ProductFilterOptions;
  selectedFilterOptions: any;
  setSelectedFilterOptions: (selectedFilterOptions) => void;
};

const ManageNotificationModal = (props: ManageNotificationModalProps) => {
  const { isOpen, onClose, formik, filterOptions, selectedFilterOptions, setSelectedFilterOptions } = props;

  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);

  const REFERENCE_FIELDS = [
    "marketingDesigners",
    "categories",
    "brandCollections",
  ];

  const filterInputStyle = {
    flex: "0 0 calc(20% - 10px)",
    margin: "8px 5px",
    width: "calc(100% - 10px)",
    "& .MuiOutlinedInput-root": {
      padding: 0,
      input: {
        paddingBottom: 0,
      },
    },
  };

  const notificationCheckBox = () => (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Notification Type
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            In App
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Email
          </Typography>
        </Grid>
      </Grid>
      <FormGroup>
        {formik.values.notificationPreferences?.map((notification, index) => (
          <Grid container key={index} spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="subtitle1" >
                {NOTIFICATION_TYPE_FRIENDLY_NAME[notification.notificationType]}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Switch
                  name={`notificationPreferences.${index}.receiveAppNotification`}
                  checked={notification.receiveAppNotification}
                  onChange={formik.handleChange}
                />}
                label=""
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Switch
                  name={`notificationPreferences.${index}.receiveEmail`}
                  checked={notification.receiveEmail}
                  onChange={formik.handleChange}
                />}
                label=""
              />
            </Grid>
          </Grid>
        ))}
      </FormGroup>
    </>
  );

  /* 
    Updates filter selection and Formik values based on the `fieldName` and `selectedItem`
   */
  const handleFilterChange = (fieldName, selectedItem) => {
    // If selectedItem is an empty array, clear the corresponding filter value in both state and Formik
    if (selectedItem?.length === 0) {
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        [fieldName]: undefined
      });

      formik.setFieldValue(fieldName, undefined);
    }
    else {
      // Check if all items in selectedItem are strings
      const containsOnlyStrings = (selectedItem || []).every(item => typeof item === "string");

      // Map the selected item based on whether it contains only strings or if it's a reference field
      const selectedValue =
        containsOnlyStrings
          ? selectedItem
          : REFERENCE_FIELDS.includes(fieldName)
            ? selectedItem?.map(item => item.id) // Extract only `id` from each object in the array
            : selectedItem?.map(item => item.name); // Extract only `name` if not in `REFERENCE_FIELDS`

      // Update the filter selection state and Formik value
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        [fieldName]: selectedItem
      });

      formik.setFieldValue(fieldName, selectedValue);
    }
  };

  const handleExpandClick = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  const renderAutocomplete = (
    fieldName,
    label,
    optionList: Array<any> | undefined,
    optionLabelGetter: (option) => string = null
  ) => (
    <Autocomplete
      multiple
      sx={filterInputStyle}
      className="filter-autocomplete-dropdown"
      value={
        selectedFilterOptions[fieldName] || []
      }
      onChange={(event, newValue) => handleFilterChange(fieldName, newValue)}
      options={optionList ? optionList : []}
      getOptionLabel={(option) =>
        optionLabelGetter ? optionLabelGetter(option) : option.name || option.description || option
      }
      disableClearable={(fieldName === FIELD_NAMES.developmentType || fieldName === "assignment")}
      limitTags={fieldName === REFERENCE_FIELDS[2] ? 1 : 2}
      renderInput={(params) => (
        <TextField
          key={params.id}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
    />
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth className="manage-notification-subscription-dialog-container">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Typography variant="h5">Manage Notifications Subscription</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="close-icon-button"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="dialog-content">
          <Card className="card">
            <CardActions className="card-actions">
              <span className="filters-title">Filters </span>
              <Button
                onClick={handleExpandClick}
                endIcon={filtersExpanded ? <ExpandLess /> : <ExpandMore />}
              >
                {filtersExpanded ? "Collapse" : "Expand"}
              </Button>
            </CardActions>
            <CardContent style={{ paddingBottom: filtersExpanded ? 2 : 0 }}>
              <Collapse in={filtersExpanded} timeout="auto" unmountOnExit>
                <Box className="flex-wrap-box">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      {renderAutocomplete(
                        "brandCollections",
                        "Brand Collection",
                        filterOptions.brands
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderAutocomplete(
                        "marketingDesigners",
                        "Designer",
                        filterOptions.productDesignerMkts
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderAutocomplete(
                        "seriesNames",
                        "Series Name",
                        filterOptions.seriesNames
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderAutocomplete(
                        "categories",
                        "Category",
                        filterOptions.productcategories
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderAutocomplete(
                        "slIntroDates",
                        "SL Intro Date",
                        filterOptions.productIntroDatess,
                        (option) => moment(option).format(DATE_FORMAT)
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
            </CardContent>
          </Card>
          {notificationCheckBox()}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">Update</Button>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ManageNotificationModal;