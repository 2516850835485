import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

interface CircularProgressWithLabelProps {
  progressValue: number; // Progress value between 0 to 100
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({ progressValue }) => {
  return (
    <Box>
      <Box width="100%">
        <CircularProgress
          variant="determinate"
          value={progressValue}
          size={45}
        />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(progressValue)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;