import { CircularProgress, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { Notification } from "../models/Notification";
import { setTimeAgoStringFromDate } from "../utils/helpers";

interface NotificationContentProps {
  notifications: Notification[];
  loading: boolean;
  hasMore: boolean;
  maxHeight?: number;
  onScroll: (event: React.UIEvent<HTMLUListElement, UIEvent>) => void;
  onNotificationClick: (notification: Notification) => void;
}

const NotificationContent: React.FC<NotificationContentProps> = ({
  notifications,
  loading,
  hasMore,
  maxHeight = 400,
  onScroll,
  onNotificationClick,
}) => {
  
  /**
   * This function is used to render notification content that contains HTML tags
   * @param content - Notification content
   */
  const renderNotificationContent = (content: string) => (
    <span dangerouslySetInnerHTML={{ __html: content }}></span>
  );

  return (
    <List
      style={{
        maxHeight: maxHeight,
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
      }}
      onScroll={onScroll}
    >
      <Typography sx={{
          margin: 0,
          padding: "10px 15px",
          backgroundColor: "#fff",
          position: "sticky",
          top: "-8px",
          zIndex: 1,
          borderBottom: "1px solid #ddd",
        }} 
        variant="h5"
        >
        Notifications
      </Typography>
      {notifications.map((notification) => (
        <ListItem
          key={notification.id}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
          onClick={() => onNotificationClick(notification)}
        >
          <ListItemText
            sx={{
              backgroundColor: `rgba(0,0,0,0.04)`,
              cursor: "pointer",
              wordWrap: "break-word",
            }}
            primary={renderNotificationContent(notification.content)}
            primaryTypographyProps={{ padding: "15px 15px 0 15px", fontSize: "14px" }}
            secondary={setTimeAgoStringFromDate(moment.utc(notification.createdOn))}
            secondaryTypographyProps={{ padding: "0 15px 15px 15px" }}
          />
        </ListItem>
      ))}
      {loading && (
        <ListItem sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </ListItem>
      )}
      {!loading && !hasMore && (
        <ListItem>
          <ListItemText
            primary="No more notifications"
            primaryTypographyProps={{ textAlign: "center" }}
          />
        </ListItem>
      )}
    </List>
  );
};

export default NotificationContent;