import { Badge, CircularProgress, IconButton, List, ListItem, ListItemText, Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { getSessionInfo, setTimeAgoStringFromDate } from "../utils/helpers";
import { NotificationListRequest } from "../models/NotificationListRequest";
import { Notification } from "../models/Notification";
import SessionService from "../services/SessionService";
import SignalRConnector from "../services/SignalRConnector";
import { toast } from "react-toastify";
import moment from "moment";
import NotificationContent from "./NotificationContent";

const Notifications = () => {

  let navigate = useNavigate();

  const sessionInfo = getSessionInfo();
  const userId = sessionInfo?.user?.id || null;

  const initialNotificationListRequest = {
    pageIndex: 1,
    pageSize: 10,
    userId: userId
  };

  const [notificationListRequest, setNotificationListRequest] = useState<NotificationListRequest>(
    initialNotificationListRequest
  );
  const [notificationsPopupVisible, setNotificationsPopupVisible] = useState<null | HTMLElement>(null);
  const open = Boolean(notificationsPopupVisible);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Fetch unread notification count when component is rendered at first time
  useEffect(() => {
    fetchUnreadNotificationCount();
  }, []);

  // Load initial notification list when popover is opened
  useEffect(() => {
    if (notificationsPopupVisible && notifications.length === 0) {
      fetchNotificationList();
    }
  }, [notificationsPopupVisible]);

  /**
   * This function is used to render notification content from api that contains html tags
   * @param content - Notification content
   */
  const renderNotificationContent = (content: string) => (
    <span dangerouslySetInnerHTML={{ __html: content }}></span>
  );

  /**
   * This function is used to display toast notification message
   * @param content - Notification content
   */
  const showNotification = (content) => {
    toast.success(renderNotificationContent(content), {
      position: 'bottom-right', // Position the toast at the bottom right corner
      autoClose: 3000, // Auto close the toast after 3 seconds
      hideProgressBar: true, // Hide the progress bar
      closeButton: false, // Do not show a close button
      theme: 'dark', // Notification theme color
      icon: false // Hide the toast icon
    });
  };

  /**
   * This function is used to update all the notification states for getting latest notifications from api
   */
  const updateNotificationStates = () => {
    setUnreadMessagesCount((unreadMessagesCount) => unreadMessagesCount + 1);
    setNotifications([]);
    setNotificationListRequest(initialNotificationListRequest);
    setHasMore(true);
  };

  // Listen the Signal-R events and display the toast notification message
  const signalREventHandlers = {
    itemCreated: (notification) => {
      showNotification(notification.content);
      updateNotificationStates();
    },

    itemModified: (notification) => {
      showNotification(notification.content);
      updateNotificationStates();
    },

    processExecutiveReview: (notification) => {
      showNotification(notification.content);
      updateNotificationStates();
    },

    productCommentUpdated: (notification) => {
      showNotification(notification.content);
      updateNotificationStates();
    }
  };

  // Subscribe and Unsubscribe to Signal-R for events
  useEffect(() => {
      const hub = SignalRConnector();
      hub.subscribe(signalREventHandlers);

      return (() => {
        hub.unsubscribe(signalREventHandlers)
      })
  }, []);

  /**
   * This function is used to fetch notification list from api and store in the state
   */
  const fetchNotificationList = async () => {
    setLoading(true);
    try {
      fetch(`${process.env.REACT_APP_API_URL}/Notification/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionInfo?.token,
        },
        body: JSON.stringify(notificationListRequest)
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((responseBodyContent) => {
        // Check if notification list is empty, then prevent to scroll the notification popup list
        if (responseBodyContent?.data?.notifications.length === 0) {
          setHasMore(false);
        }
        else {
          setNotifications((prevNotifications) => [...prevNotifications, ...responseBodyContent?.data?.notifications || []]);
          setNotificationListRequest({
            ...notificationListRequest,
            pageIndex: notificationListRequest.pageIndex + 1
          })
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.error("Exception from notification list", error);
      });
    } catch (error) {
      console.error('Error fetching notification list:', error);
      setLoading(false);
    }
  };

  /**
   * This function is used to fetch unread notification count from api and store in the state
   */
  const fetchUnreadNotificationCount = () => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}/Notification/unread_count/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionInfo?.token,
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((responseBodyContent) => {
        if (responseBodyContent.data) {
          setUnreadMessagesCount(responseBodyContent.data);
        }
      }).catch((error) => {
        console.error("Exception from unread notification count", error);
      });
    } catch (error: any) {
      console.error("Exception from unread notification count", error);
    }
  };

  /**
   * This function is used to handle api call only if unread notification count is greater than zero
   */
  const checkUnreadNotificationCount = () => {
    if (unreadMessagesCount > 0) {
      handleMarkAllNotificationAsRead();
    }
  };

  /**
   * This function is used to mark all notification as read in api and update the state
   */
  const handleMarkAllNotificationAsRead = () => {
    try {
      fetch(`${process.env.REACT_APP_API_URL}/Notification/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionInfo?.token,
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((responseBodyContent) => {
        if (responseBodyContent.data === true) {
          setUnreadMessagesCount(0);
        }
      }).catch((error) => {
        console.error("Exception from mark all notification as read", error);
      });
    } catch (error) {
      console.error('Error from mark all notification as read:', error);
    }
  };

  // This function is used to open the notification popup
  const handlePopupOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsPopupVisible(event.currentTarget);
  };

  // This function is used to close the notification popup
  const handlePopupClose = () => {
    setNotificationsPopupVisible(null);
  };

  /**
   * This function is used to handle notification popup scroll events and load more notifications
   */
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (((scrollHeight - scrollTop).toFixed() == clientHeight) && !loading && hasMore) {
      fetchNotificationList();
    }
  };

  /**
   * This function is used to handle notification content click and redirect to edit product page
   */
  const handleNotificationClick = (notification: Notification) => {
    navigate(`/edit-product/${notification.link}`);
    handlePopupClose();
  };

  return (
    <>
      <IconButton
        id="basic-button"
        title="Notifications"
        aria-haspopup="true"
        onClick={handlePopupOpen}>
        <Badge color="primary" badgeContent={unreadMessagesCount} onClick={checkUnreadNotificationCount}>
          <NotificationsIcon sx={{ color: "#484848" }} />
        </Badge>
      </IconButton>
      <Popover
        id="basic-menu"
        anchorEl={notificationsPopupVisible}
        open={open}
        onClose={handlePopupClose}
        disableScrollLock={true}
        className="notification_popup"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {
              width: 400,
              marginTop: "15px",
              padding: "5px"
            }
          },
        }}
      >
        <span className="notification_popup-arrow"></span>
        <NotificationContent
          notifications={notifications}
          loading={loading}
          hasMore={hasMore}
          onScroll={handleScroll}
          onNotificationClick={handleNotificationClick}
        />
      </Popover>
    </>
  )
}

export default Notifications;