import { Box, Button, Fade, Popper, PopperPlacementType, createSvgIcon } from "@mui/material";
import { useEffect, useState } from "react";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { FactoryRounded } from "@mui/icons-material";
import api from "../services/ApiService";
import { ApiResponse } from "../models/ApiResponse";
import { toast } from "react-toastify";
import moment from "moment";
import SessionService from "../services/SessionService";
import { useAppSelector } from "../store";
import { Permissions, } from "../models/Enum";

interface ExportProductListingProps {
    selectedRows: any[];
    productsTotalCount: number;
    selectAll: boolean;
    setLoading: (loading: boolean) => void;
};

const ExportProductListing = (props: ExportProductListingProps) => {
    const { selectedRows, selectAll, productsTotalCount, setLoading } = props;
    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt',
    );

    const itemListRequestState = useAppSelector((state) => state.items.itemListRequest);
    const productListFilterValuesSelected = useAppSelector((state) => state.filters.productListView.selectedFilterValues);

    const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);

    const sessionService = SessionService.getInstance();

    const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
      setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
    };

    //openPopper check if the popper is open or not;
    const openPopper = Boolean(popperAnchorEl);

    //needed for when use click out of popper
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            //if popper is open and click is not popper then set anchor to null which close the popper
            if (popperAnchorEl && !popperAnchorEl.contains(event.target as Node)) {
              setPopperAnchorEl(null);
            }
        };

        if (openPopper) {
            document.addEventListener('mousedown',handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        } return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopper]);
    
    const fetchItemIds = async () => {
        const response = await api.post<ApiResponse<any>>('/Item/get-product-id-list-filter', itemListRequestState)
        if(response.isSuccess) {
            return response?.data || [];
        }
    }

    const downloadFile = async (response, fileName) => {
        const date = moment();
        const formatedate = date.format('YYYYMMDDHHMMSS')
        const blobData = await response.blob();
    
        //Create a download link and trigger the download
        const blobURL = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style.display = 'none';
        //Add link element to inititate the download
        document.body.appendChild(a);
        a.download = fileName+formatedate;
        //click the link element to initiate the dowload
        a.click();
        a.remove();
        URL.revokeObjectURL(blobURL);
      };

    const invokeDownload = async (requestInput: any, inputFileName, inputFileFormat) => {
      try{
        const sessionInfo = sessionService.getSessionToken();
        let url = '';
        let requestBody = requestInput;

          switch (inputFileFormat) {
              case 'BOM SAP':
                  url = `${process.env.REACT_APP_API_URL}/Item/export-bom-csv-sap-import`;
                  break;
              case 'BOM JDE':
                  url = `${process.env.REACT_APP_API_URL}/Item/export-bom-csv-jde-import`;
                  break;
              case 'Factory Sample':
                  url = `${process.env.REACT_APP_API_URL}/Item/factory-sample-request`;
                  requestBody = { stockCodes: requestInput };
                  break;
              case 'Product List':
                  url = `${process.env.REACT_APP_API_URL}/Item/export-product-list-csv`;
                  break;
              case 'Inriver Pim':
                  url = `${process.env.REACT_APP_API_URL}/Item/inriver-pim-export`;
                  break;
              case 'SAP':
                  url = `${process.env.REACT_APP_API_URL}/Item/export-csv-sap-import`;
                  break;
              case 'JDE Components':
                  url = `${process.env.REACT_APP_API_URL}/Component/jde-export`;
                  break;
              default:
                  throw new Error('Invalid download type');
          }
        
        setLoading(true);
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionInfo,
          },
          body: JSON.stringify(requestBody)
        });

        const contentType = response.headers.get("content-type");
        let fileName = inputFileName;
          if (response.ok) {
            if(inputFileFormat === "Factory Sample") {
                const bodyResp = await response.json();
                
                if (bodyResp.isSuccess) {
                    const fileName = bodyResp.data;
              
                    // Open new window for download
                    window.open(`${process.env.REACT_APP_API_URL}/Item/download-factorysample-zip?fileName=${fileName}`, '_blank');
                    toast.success(`${inputFileFormat} Exported Successfully`);
                }
                else {
                    toast.error(bodyResp.message);
                }

                setLoading(false);
            }
            else if (contentType && contentType.includes('application/json')) {
                const bodyResp = await response.json();
                toast.error(bodyResp.message);
                setLoading(false);
            } else if (contentType && (contentType.includes('text/csv') ||
                contentType.includes('application/zip') ||
                contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))) {
                downloadFile(response, fileName);
                toast.success(`${inputFileFormat} Exported Successfully`);
                setLoading(false);
            }
        } 
        else if (response.status === 404) {
            toast.error("No data found");
            setLoading(false);
        } else {
            setLoading(false);
            throw new Error();
        }
      }catch (error) {
        setLoading(false);
        console.error('Error while downloading file:', error);
        toast.error('Error while downloading file'); 
      }
    };

    //CSV handling for each option
    const handleCSVforSAP = async () => {
        let selectedId = selectedRows.map(i => i.id);
        let fileName = 'BOM_SAP_';
        let fileFormat = 'BOM SAP'
        
        if(selectAll){
            const idListResponse = await fetchItemIds();
            await invokeDownload(idListResponse, fileName, fileFormat)
        }else if(selectedRows.length > 0) {
            await invokeDownload(selectedId,fileName, fileFormat);
        } else {
            toast.error("Select a Product or Select All to Export");
        }

    };

    const handleCSVforJDE = async () => {
        let selectedId = selectedRows.map(i => i.id);
        let fileName = 'BOM_JDE_';
        let fileFormat = 'BOM JDE'

        if(selectAll){
            const idListResponse = await fetchItemIds();
            await invokeDownload(idListResponse, fileName, fileFormat)
        }else if(selectedRows.length > 0) {
            await invokeDownload(selectedId,fileName, fileFormat);
        } else {
            toast.error("Select a Product or Select All to Export");
        }
    };

    const handleFactorySample = async () => {
        let selectedStockCodes = selectedRows.map(row => row.stockCode); 
        let fileName = 'FactorySample_';
        let fileFormat = 'Factory Sample';
        if (selectedRows.length <= 0) {
            toast.error("There is no Stock Code, Please add record that have Stock Code");
          } else if(selectedRows.length>25) {
            toast.error("Only 25 Records are Allow to Download");
          }else if(selectedStockCodes.some(value => value === null || value === '')){
             toast.error("There are Record with no Stock Code, Please Deselect Record with no Stock Code");
          }
          else{
            await invokeDownload(selectedStockCodes, fileName, fileFormat);
          }
    };

    const handleCSVforProductList = async () => {
        let fileName = 'Product_List_';
        let fileFormat = 'Product List';
        if(productsTotalCount > 5000) {
          toast.error("Cannot export more than 5000 records. Please narrow down the list using filter options and try again");
        }
        else {
            await invokeDownload(productListFilterValuesSelected, fileName, fileFormat);
        }
    };

    const handleExportForInriverPim = async () => {
        let fileName = 'Inriver_PIM_';
        let fileFormat = 'Inriver Pim';
        if(productsTotalCount > 5000) {
          toast.error("Cannot export more than 5000 records. Please narrow down the list using filter options and try again");
        }
        else {
            await invokeDownload(productListFilterValuesSelected, fileName, fileFormat);
        }
    };

    const handleExportToCSVForSAP = async () => {
        let fileName = 'SAP_Import_';
        let fileFormat = 'SAP';
        if(productsTotalCount > 5000) {
          toast.error("Cannot export more than 5000 records. Please narrow down the list using filter options and try again");
        }
        else {
            await invokeDownload(productListFilterValuesSelected, fileName, fileFormat);
        }
    };

    const handleExportJDEComponentsToExcel = async () => {
        let fileName = 'JDE_Components_';
        let fileFormat = 'JDE Components';
        if(productsTotalCount > 5000) {
          toast.error("Cannot export more than 5000 records. Please narrow down the list using filter options and try again");
        }
        else {
            await invokeDownload(productListFilterValuesSelected, fileName, fileFormat);
        }
    };

    return(
        <>
            <Button
                startIcon={<ExportIcon />}
                onClick = {handlePopper}
            >
                EXPORT
            </Button>
                {<Popper
                    open={openPopper}
                    anchorEl={popperAnchorEl}
                    placement={"bottom-start"}
                    modifiers={[
                        {
                        name: 'flip',
                        enabled: false,
                        },
                        {
                        name: 'preventOverflow',
                        options: {
                            altBoundary: true,
                            tether: false,
                        },
                        },
                    ]}
                    transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout = {250}>
                                <Box 
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        bgcolor: 'background.paper',
                                        border: 1,
                                        borderColor: 'grey.300',
                                        borderRadius: 1,
                                        alignItems: "flex-start",
                                        padding: 1
                                    }}
                                >
                                    <Button
                                        startIcon = {<FileDownloadRoundedIcon/>}
                                        onClick={handleCSVforSAP}
                                    >
                                        Export BOM to CSV SAP
                                    </Button>
                                    <Button
                                        startIcon = {<FileDownloadRoundedIcon/>}
                                        onClick={handleCSVforJDE}
                                    >
                                        Export BOM to CSV JDE
                                    </Button>
                                    {sessionService.hasPermission(Permissions.UpdateProduct) 
                                    && <Button
                                        startIcon = {<FactoryRounded/>}
                                        onClick={handleFactorySample}
                                    >
                                        Export Factory Sample Request
                                    </Button>}
                                    <Button
                                        startIcon = {<FileDownloadRoundedIcon/>}
                                        onClick={handleCSVforProductList}
                                    >
                                        Export Products to CSV
                                    </Button>
                                    <Button
                                        startIcon = {<FileDownloadRoundedIcon/>}
                                        onClick={handleExportForInriverPim}
                                    >
                                        Export to Excel for PIM
                                    </Button>
                                    <Button
                                        startIcon = {<FileDownloadRoundedIcon/>}
                                        onClick={handleExportToCSVForSAP}
                                    >
                                        Export to CSV for SAP
                                    </Button>
                                    {/* <Button
                                        startIcon = {<FileDownloadRoundedIcon/>}
                                        onClick={handleExportJDEComponentsToExcel}
                                    >
                                        Export JDE Components To Excel
                                    </Button> */}
                                </Box> 
                            </Fade>
                        )}     
                </Popper>}
            </>
    )
};

export default ExportProductListing;