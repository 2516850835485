import { createSlice } from '@reduxjs/toolkit';

interface GlobalLoadingState {
  loader: boolean;
}

const initialState: GlobalLoadingState = {
  loader: false,
};

const globalLoading = createSlice({
  name: 'globalLoading',
  initialState,
  reducers: {
    enableLoader(state) {
      state.loader = true;
    },
    disableLoader(state) {
      state.loader = false;
    },
  },
});

export const { enableLoader, disableLoader } = globalLoading.actions;

export default globalLoading.reducer;