import Box from "@mui/material/Box";
import {
    Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Collapse,
    FormControlLabel,
    TextField,
} from "@mui/material";
import { useState, useEffect, useCallback, useRef } from "react";
import { ApiResponse } from "../../models/ApiResponse";
import api from "../../services/ApiService";
import { ProductFilterOptions } from "../../models/Items/FilterOptions";
import { SelectedFilters } from "../../models/Items/ItemListRequest";
import { ExpandMore, ExpandLess, Padding, Margin } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  updateDashboardViewSelectedFilterOptions,
  updateDashboardViewSelectedFilterValues,
  updateProductListViewSelectedFilterOptions,
  updateProductListViewSelectedFilterValues,
  updateQuoteListViewSelectedFilterOptions,
  updateQuoteListViewSelectedFilterValues
} from '../../reducers/filtersReducer';
import { Permissions, PermissionsFriendlyNames } from "../../models/Enum";
import { FIELD_NAMES, NO_TAGS_OPTION, PRODUCT_TYPE_DROPDOWN_OPTIONS, PROJECT_MANAGER_ASSIGNMENT, QUOTE_APPROVAL_TYPE_DROPDOWN_OPTIONS } from "./EditItem/Constants";
import { PrjStageWithStatus } from "../../models/PrjStage";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/constants";
import { Tag } from "../../models/Tag";
import { updateActiveProjectListSelectedFilterOptions, updateActiveProjectListSelectedFilterValues } from "../../reducers/projectDashboardReducer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type FiltersProps = {
  onApplyFilter: (filterValues: SelectedFilters) => void;
  filtersExpanded: boolean;
  handleExpandClick: () => void;
  viewMode: string;
};

const Filters = (props: FiltersProps) => {

  const {
    filtersExpanded,
    viewMode,
    onApplyFilter,
    handleExpandClick
  } = props;

  // these fields stores the reference id and not the actual value e.g. Stage, Category
  const REFERENCE_FIELDS = [
    "stage",
    "status",
    "marketingDesigner",
    "category",
    "function",
    "brand",
    "primaryMaterial",
    "secondaryMaterial",
    "developmentType",
    "quoteApprovalType",
    "vendor",
    "assignment"
  ];

  const initialSelectedFilters: SelectedFilters = {};
  const [filterOptions, setFilterOptions] = useState<ProductFilterOptions>({});
  const [productListStatusDisabled, setProductListStatusDisabled] = useState<boolean>(false);
  const [dashboardStatusDisabled, setDashboardStatusDisabled] = useState<boolean>(false);
  const [quoteListStatusDisabled, setQuoteListStatusDisabled] = useState<boolean>(false);
  const [projectOverviewDashboardStatusDisabled, setProjectOverviewDashboardStatusDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  
  const productListFilterOptionsSelected = useAppSelector((state) => state.filters.productListView.selectedFilterOptions);
  const productListFilterValuesSelected = useAppSelector((state) => state.filters.productListView.selectedFilterValues);

  const quoteListFilterOptionsSelected = useAppSelector((state) => state.filters.quoteListView.selectedFilterOptions);
  const quoteListFilterValuesSelected = useAppSelector((state) => state.filters.quoteListView.selectedFilterValues);
  
  const dashboardFilterOptionsSelected = useAppSelector((state) => state.filters.dashboardView.selectedFilterOptions);
  const dashboardFilterValuesSelected = useAppSelector((state) => state.filters.dashboardView.selectedFilterValues);
  const dashboardExecutiveReviewTagId = useAppSelector((state) => state.filters.dashboardView.reviewRequiredTagId);

  const projectOverviewDashboardFilterOptionsSelected = useAppSelector((state) => state.projectDashboard.activeProjectListFilters.selectedFilterOptions);
  const projectOverviewDashboardFilterValuesSelected = useAppSelector((state) => state.projectDashboard.activeProjectListFilters.selectedFilterValues);

  const isEffectExecuted = useRef(false);
  
  useEffect(() => {
    getAllFilterOptions();
    if(!productListFilterValuesSelected?.developmentType) {
      setSelectedProductTypeFilter();
    }
  }, []);

  useEffect(() => {
    if (isEffectExecuted.current) return;

    if (Object.keys(filterOptions).length > 0) {
      isEffectExecuted.current = true; // Ensure this runs only once
      if (dashboardExecutiveReviewTagId) {
        setSelectedTagsFilter();
      }

      if(viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard]) {
        const findProjectManagerAssignment = filterOptions?.assignments?.find((assignment) => assignment.name === PROJECT_MANAGER_ASSIGNMENT);
        
        dispatch(updateActiveProjectListSelectedFilterValues({
          ...projectOverviewDashboardFilterValuesSelected,
          assignment: projectOverviewDashboardFilterValuesSelected?.assignment || findProjectManagerAssignment?.id,
        }));
        dispatch(updateActiveProjectListSelectedFilterOptions({
          ...projectOverviewDashboardFilterOptionsSelected,
          assignment: projectOverviewDashboardFilterOptionsSelected?.assignment || findProjectManagerAssignment,
        }));
      }
    }
  }, [filterOptions, dashboardExecutiveReviewTagId]);

  const prepareStatusDropDownOptionsBasedOnStage = useCallback((stageId) => {
    if (stageId && filterOptions?.stageStatus?.length) {
      const statusDropDownOptions = filterOptions.stageStatus
        .find(stage => stage.id === stageId)?.statuses.map(s => ({
          id: s.id,
          description: s.description,
        })) || [];
      
      const currentStatuses = filterOptions.statuses || [];
      const statusChanged = JSON.stringify(currentStatuses) !== JSON.stringify(statusDropDownOptions);
      
      if (statusChanged) {
        setFilterOptions({
          ...filterOptions,
          statuses: statusDropDownOptions
        });
      }
    }
  }, [filterOptions, setFilterOptions]);

  const setStatusDropDownBasedOnStageValue = useCallback(() => {
    if (viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard] && dashboardFilterValuesSelected?.stage) {
      prepareStatusDropDownOptionsBasedOnStage(dashboardFilterValuesSelected.stage);
      setDashboardStatusDisabled(false);
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard] && !dashboardFilterValuesSelected?.stage) {
      setDashboardStatusDisabled(true);
    }

    if (viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] && productListFilterValuesSelected?.stage) {
      prepareStatusDropDownOptionsBasedOnStage(productListFilterValuesSelected.stage);
      setProductListStatusDisabled(false);
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] && !productListFilterValuesSelected?.stage) {
      setProductListStatusDisabled(true);
    }

    if (viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] && quoteListFilterValuesSelected?.stage) {
      prepareStatusDropDownOptionsBasedOnStage(quoteListFilterValuesSelected.stage);
      setQuoteListStatusDisabled(false);
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] && !quoteListFilterValuesSelected?.stage) {
      setQuoteListStatusDisabled(true);
    }

    if (viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] && projectOverviewDashboardFilterValuesSelected?.stage) {
      prepareStatusDropDownOptionsBasedOnStage(projectOverviewDashboardFilterValuesSelected.stage);
      setProjectOverviewDashboardStatusDisabled(false);
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] && !projectOverviewDashboardFilterValuesSelected?.stage) {
      setProjectOverviewDashboardStatusDisabled(true);
    }

  }, [
    viewMode,
    dashboardFilterValuesSelected,
    productListFilterValuesSelected,
    quoteListFilterValuesSelected,
    projectOverviewDashboardFilterValuesSelected,
    prepareStatusDropDownOptionsBasedOnStage,
    PermissionsFriendlyNames,
    Permissions,
  ]);

  useEffect(() => {
    setStatusDropDownBasedOnStageValue();
  }, [
    dashboardFilterValuesSelected,
    productListFilterValuesSelected,
    quoteListFilterValuesSelected,
    projectOverviewDashboardFilterValuesSelected,
    setStatusDropDownBasedOnStageValue
  ]);

  const setSelectedTagsFilter = () => {
    if (filterOptions?.projectTags?.length > 0) {
      const setReviewTagFromOptions = filterOptions.projectTags.find((pt) => pt.id === dashboardExecutiveReviewTagId);
      dispatch(updateDashboardViewSelectedFilterValues({
        ...dashboardFilterValuesSelected,
        tags: [setReviewTagFromOptions.id],
      }));

      dispatch(updateDashboardViewSelectedFilterOptions({
        ...dashboardFilterOptionsSelected,
        tags: [setReviewTagFromOptions],
      }));
    }
  };

  const setSelectedProductTypeFilter = () => {
    dispatch(updateProductListViewSelectedFilterValues({
      ...productListFilterValuesSelected,
      developmentType: PRODUCT_TYPE_DROPDOWN_OPTIONS[0].id,
    }));

    dispatch(updateProductListViewSelectedFilterOptions({
      ...productListFilterOptionsSelected,
      developmentType: PRODUCT_TYPE_DROPDOWN_OPTIONS[0],
    }));
  };

  const getAllFilterOptions = async () => {
    try {
      const dropDownSources = await api.get<ApiResponse<ProductFilterOptions>>(`/Item/filter-options`);

      if (dropDownSources?.isSuccess) {
        const stageStatusResponse = api
          .get<ApiResponse<PrjStageWithStatus[]>>(`/PickList/project-stage-stauses-list`)
          .then((response) => {
            if (response?.isSuccess) {
              return response;
            }
          })
          .catch((error) => {
            console.error("Exception from stage status list api", error);
          });

        const [
          stageStatus,
        ] = await Promise.all([
          stageStatusResponse,
        ]);

        if(viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]) {
          const addNoTagsOption: Tag[] = [...(dropDownSources?.data?.projectTags || []), NO_TAGS_OPTION];
          dropDownSources.data.projectTags = addNoTagsOption;
        }

        dropDownSources.data.stageStatus = (stageStatus as ApiResponse<PrjStageWithStatus[]>).data;
        setFilterOptions(dropDownSources.data);
      }
    } catch (error) {
      console.error("Exception from filter options", error);
    }
  };

  const clearFilterSelections = (viewMode, fieldName, selectedFilterValues, selectedFilterOptions) => {
    const isStageField = fieldName === FIELD_NAMES.stage;

    if (viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]) {
      dispatch(updateProductListViewSelectedFilterValues({
        ...selectedFilterValues,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
      dispatch(updateProductListViewSelectedFilterOptions({
        ...selectedFilterOptions,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
    }
    else if (viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes]) {
      dispatch(updateQuoteListViewSelectedFilterValues({
        ...selectedFilterValues,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
      dispatch(updateQuoteListViewSelectedFilterOptions({
        ...selectedFilterOptions,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
    }
    else if (viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard]) {
      dispatch(updateActiveProjectListSelectedFilterValues({
        ...selectedFilterValues,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
      dispatch(updateActiveProjectListSelectedFilterOptions({
        ...selectedFilterOptions,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
    }
    else {
      dispatch(updateDashboardViewSelectedFilterValues({
        ...selectedFilterValues,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
      dispatch(updateDashboardViewSelectedFilterOptions({
        ...selectedFilterOptions,
        [fieldName]: undefined,
        ...(isStageField && { status: undefined }),
      }));
    }
  };

  const applyFilterSelection = (viewMode, fieldName, selectedValue, selectedItem) => {
    const isStageField = fieldName === FIELD_NAMES.stage;
    const dispatchActions = {
      [PermissionsFriendlyNames[Permissions.ViewProducts]]: {
        updateValues: updateProductListViewSelectedFilterValues,
        updateOptions: updateProductListViewSelectedFilterOptions,
        values: productListFilterValuesSelected,
        options: productListFilterOptionsSelected,
      },
      [PermissionsFriendlyNames[Permissions.ViewQuotes]]: {
        updateValues: updateQuoteListViewSelectedFilterValues,
        updateOptions: updateQuoteListViewSelectedFilterOptions,
        values: quoteListFilterValuesSelected,
        options: quoteListFilterOptionsSelected,
      },
      [PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard]]: {
        updateValues: updateActiveProjectListSelectedFilterValues,
        updateOptions: updateActiveProjectListSelectedFilterOptions,
        values: projectOverviewDashboardFilterValuesSelected,
        options: projectOverviewDashboardFilterOptionsSelected,
      },
      default: {
        updateValues: updateDashboardViewSelectedFilterValues,
        updateOptions: updateDashboardViewSelectedFilterOptions,
        values: dashboardFilterValuesSelected,
        options: dashboardFilterOptionsSelected,
      }
    };

    const { updateValues, updateOptions, values, options } = dispatchActions[viewMode] || dispatchActions.default;

    dispatch(updateValues({
      ...values,
      [fieldName]: selectedValue,
      ...(isStageField && { status: undefined }),
    }));

    dispatch(updateOptions({
      ...options,
      [fieldName]: selectedItem,
      ...(isStageField && { status: undefined }),
    }));
  };

  const handleFilterChange = (fieldName, selectedItem) => {
    if (!selectedItem) {
      const selectedFilterValues = viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]
        ? productListFilterValuesSelected
        : viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes]
          ? quoteListFilterValuesSelected 
        : viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] ? 
        projectOverviewDashboardFilterValuesSelected : dashboardFilterValuesSelected;
      

      const selectedFilterOptions = viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]
        ? productListFilterOptionsSelected
        : viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes]
          ? quoteListFilterOptionsSelected 
        : viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] ?
        projectOverviewDashboardFilterOptionsSelected : dashboardFilterOptionsSelected;

      clearFilterSelections(viewMode, fieldName, selectedFilterValues, selectedFilterOptions);

    } else {
      const selectedValue =
        typeof selectedItem === "string"
          ? selectedItem
          : REFERENCE_FIELDS.includes(fieldName)
            ? selectedItem.id
            : selectedItem.name;
      applyFilterSelection(viewMode, fieldName, selectedValue, selectedItem);
    }
  };

  const HandleCheckboxChange = (fieldName, checkedItem) => {
    dispatch(updateProductListViewSelectedFilterValues({
      ...productListFilterValuesSelected,
      [fieldName]: checkedItem,
    }));

    dispatch(updateProductListViewSelectedFilterOptions({
      ...productListFilterOptionsSelected,
      [fieldName]: checkedItem,
    }));
  } 

  const handleTagsFilterChange = (fieldName, selectedTags, reason) => {

    // store the array with TagIds to send into the api request
    let selectedTagIDs = selectedTags.map(t => t.id);
    if (viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]) {

      // If "Select All" is clicked, either select all or clear all
      if (selectedTags.includes('selectAll')) {
        const selectAllTagIds = filterOptions?.projectTags?.map(t => t.id);

        dispatch(updateProductListViewSelectedFilterValues({
          ...productListFilterValuesSelected,
          [fieldName]: selectAllTagIds,
        }));

        // store the selected tag option in state to display dropdown value selection 
        dispatch(updateProductListViewSelectedFilterOptions({
          ...productListFilterOptionsSelected,
          [fieldName]: isAllSelected ? [] : filterOptions?.projectTags,
        }));

      } else {

        dispatch(updateProductListViewSelectedFilterValues({
          ...productListFilterValuesSelected,
          [fieldName]: selectedTagIDs,
        }));
    
        // store the selected tag option in state to display dropdown value selection 
        dispatch(updateProductListViewSelectedFilterOptions({
          ...productListFilterOptionsSelected,
          [fieldName]: selectedTags,
        }));
      }
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes]) {
      dispatch(updateQuoteListViewSelectedFilterValues({
        ...quoteListFilterValuesSelected,
        [fieldName]: selectedTagIDs,
      }));
  
      // store the selected tag option in state to display dropdown value selection 
      dispatch(updateQuoteListViewSelectedFilterOptions({
        ...quoteListFilterOptionsSelected,
        [fieldName]: selectedTags,
      }));
    }
    else if (viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard]) {
      if (reason === 'removeOption' && selectedTags.findIndex(option => option.id === dashboardExecutiveReviewTagId) === -1) {
        const setReviewTagFromOptions = filterOptions?.projectTags?.find((pt) => pt.id === dashboardExecutiveReviewTagId);
        selectedTags.unshift(setReviewTagFromOptions);
        selectedTagIDs.unshift(dashboardExecutiveReviewTagId);
      }
      dispatch(updateDashboardViewSelectedFilterValues({
        ...dashboardFilterValuesSelected,
        [fieldName]: selectedTagIDs,
      }));

      // store the selected tag option in state to display dropdown value selection 
      dispatch(updateDashboardViewSelectedFilterOptions({
        ...dashboardFilterOptionsSelected,
        [fieldName]: selectedTags,
      }));
    }
  };

  /**
   * This function is used to display filter selected value based on page view 
   * @param fieldName - Name of the field
   */
  const renderFilterOptionsSelected = (fieldName: string) => {
    if (viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]) {
      if(fieldName === "tags") {
        return productListFilterOptionsSelected[fieldName]
        ? productListFilterOptionsSelected[fieldName]
        : []
      }
      else if(fieldName === "projectCode") {
        return productListFilterOptionsSelected[fieldName]
          ? productListFilterOptionsSelected[fieldName]
          : ""
      }else if (fieldName === "hideReleasedToInriver" || fieldName === "hideArchivedDevelopment") {
        return productListFilterOptionsSelected[fieldName]
        ? productListFilterOptionsSelected[fieldName]
        : null
      }else {
        return productListFilterOptionsSelected[fieldName]
          ? productListFilterOptionsSelected[fieldName]
          : null
      } 
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes]) {
      if(fieldName === "tags") {
        return quoteListFilterOptionsSelected[fieldName]
        ? quoteListFilterOptionsSelected[fieldName]
        : []
      }
      else if(fieldName === "projectCode") {
        return quoteListFilterOptionsSelected[fieldName]
          ? quoteListFilterOptionsSelected[fieldName]
          : ""
      }
      else {
        return quoteListFilterOptionsSelected[fieldName]
          ? quoteListFilterOptionsSelected[fieldName]
          : null
      }
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard]) {
      return projectOverviewDashboardFilterOptionsSelected[fieldName]
          ? projectOverviewDashboardFilterOptionsSelected[fieldName]
          : null
    }
    else if (viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard]) {
      if(fieldName === "tags") {
        return dashboardFilterOptionsSelected[fieldName]
          ? dashboardFilterOptionsSelected[fieldName]
          : []
      }
      else if(fieldName === "projectCode") {
        return dashboardFilterOptionsSelected[fieldName]
          ? dashboardFilterOptionsSelected[fieldName]
          : ""
      }
      else {
        return dashboardFilterOptionsSelected[fieldName]
          ? dashboardFilterOptionsSelected[fieldName]
          : null
      }
    }
  };

  const filterInputStyle = {
    flex: "0 0 calc(20% - 10px)",
    margin: "8px 5px",
    width: "206px",
    "& .MuiOutlinedInput-root": {
      padding: 0,
      input: {
        paddingBottom: 0,
      },
    },
  };

  const isStatusFieldDisabled = (fieldName: string) => {
    return fieldName === FIELD_NAMES.status &&
      ((viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] && productListStatusDisabled) ||
        (viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard] && dashboardStatusDisabled) ||
        (viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] && quoteListStatusDisabled) ||
        (viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] && projectOverviewDashboardStatusDisabled)
      )
  };

  const renderAutocomplete = (
    fieldName,
    label,
    optionList: Array<any> | undefined,
    optionLabelGetter: (option) => string = null
  ) => (
    <Autocomplete
      sx={filterInputStyle}
      className="filter-autocomplete-dropdown"
      value={
        renderFilterOptionsSelected(fieldName)
      }
      onChange={(event, newValue) => handleFilterChange(fieldName, newValue)}
      options={optionList ? optionList : []}
      getOptionLabel={(option) =>
        optionLabelGetter ? optionLabelGetter(option) : option.name || option.description || option
      }
      disableClearable={(fieldName === FIELD_NAMES.developmentType || fieldName === "assignment")}
      disabled={isStatusFieldDisabled(fieldName)}
      style={{
        backgroundColor: isStatusFieldDisabled(fieldName) ? "#EBEBE4" : "#FFFFFF"
      }}
      renderInput={(params) => (
        <TextField
          key={params.id}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
    />
  );

  const renderCheckbox = (
    fieldName,
    label,
    onChange
  ) => (
    <FormControlLabel sx={{marginLeft: "6px"}}
    control={
      <Checkbox 
        name={fieldName}
        checked={productListFilterValuesSelected[fieldName]}
        onChange={onChange}
      />
    } 
    label={label}              
  />
  );

  const isAllSelected = productListFilterOptionsSelected?.tags?.length === (filterOptions?.projectTags?.length || 0);

  // Conditional inclusion of "Select All" based on view mode
  const optionsWithSelectAll = (optionList) => {
    return viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]
      ? ['selectAll', ...(optionList || [])]
      : [...(optionList || [])];
  };

  const renderMultiSelectAutocomplete = (
    fieldName,
    label,
    optionList: Array<any> | undefined,
    optionLabelGetter: (option) => string = null
  ) => (
    <Autocomplete
      sx={filterInputStyle}
      value={
        renderFilterOptionsSelected(fieldName)
      }
      onChange={(event, newValue, reason) => handleTagsFilterChange(fieldName, newValue, reason)}
      options={optionsWithSelectAll(optionList)}
      getOptionLabel={(option) =>
        viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] && option === 'selectAll'
          ? 'Select All'
          : optionLabelGetter
          ? optionLabelGetter(option)
          : option?.name || option
      }
      getOptionDisabled={(option) => viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard] && option.id === dashboardExecutiveReviewTagId}
      multiple={true}
      limitTags={2}
      disableClearable={viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard]}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={
              viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] && option === 'selectAll'
                ? isAllSelected
                : selected
            }
          />
          {option === 'selectAll' ? 'Select All' : option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          key={params.id}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
    />
  );

  const handleResetFilterClick = () => {
    if (viewMode === PermissionsFriendlyNames[Permissions.ViewProducts]) {
      dispatch(updateProductListViewSelectedFilterValues({
        developmentType: PRODUCT_TYPE_DROPDOWN_OPTIONS[0].id,
        hideReleasedToInriver: false,
        hideArchivedDevelopment: false,
      }));
  
      dispatch(updateProductListViewSelectedFilterOptions({
        developmentType: PRODUCT_TYPE_DROPDOWN_OPTIONS[0],
        hideReleasedToInriver: false,
        hideArchivedDevelopment: false,
      }));
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes]) {
      dispatch(updateQuoteListViewSelectedFilterValues(initialSelectedFilters));

      dispatch(updateQuoteListViewSelectedFilterOptions({}));
    }
    else if(viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard]) {
      const findProjectManagerAssignment = filterOptions?.assignments?.find((assignment) => assignment.name === PROJECT_MANAGER_ASSIGNMENT);

      dispatch(updateActiveProjectListSelectedFilterValues({
        assignment: findProjectManagerAssignment?.id,
      }));

      dispatch(updateActiveProjectListSelectedFilterOptions({
        assignment: findProjectManagerAssignment
      }));
    }
    else {
        const setReviewTagFromOptions = filterOptions?.projectTags?.find((pt) => pt.id === dashboardExecutiveReviewTagId);
        dispatch(updateDashboardViewSelectedFilterValues({
          tags: [setReviewTagFromOptions?.id],
        }));
  
        dispatch(updateDashboardViewSelectedFilterOptions({
          tags: [setReviewTagFromOptions],
        }));
    }
    onApplyFilter({});
  };

  const shouldDisplayAllStatuses = () => {
    return (
      (viewMode === PermissionsFriendlyNames[Permissions.ExecutiveDashboard] && !dashboardFilterValuesSelected?.stage) ||
      (viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] && !productListFilterValuesSelected?.stage) || 
      (viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] && !quoteListFilterValuesSelected?.stage) ||
      (viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] && !projectOverviewDashboardFilterValuesSelected?.stage)
    );
  };
  
  const autoCompleteStatuses = shouldDisplayAllStatuses() ? filterOptions.projectStatuses : filterOptions.statuses;

  return (
    <Card sx={{ marginBottom: 1 }}>
      <CardActions
        style={{ justifyContent: "space-between", padding: "0 20px" }}
      >
        <span style={{ fontSize: "16px", fontWeight: 700 }}>Filters </span>
        <Button
          onClick={handleExpandClick}
          endIcon={filtersExpanded ? <ExpandLess /> : <ExpandMore />}
        >
          {filtersExpanded ? "Collapse" : "Expand"}
        </Button>
      </CardActions>
      <CardContent style={{ paddingBottom: filtersExpanded ? 2 : 0 }}>
        <Collapse in={filtersExpanded} timeout="auto" unmountOnExit>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {renderAutocomplete("brand", "Brand Collection", filterOptions.brands)}
            {renderAutocomplete("stage", "Stage", filterOptions.projectStages)}
            {renderAutocomplete(
              "status",
              "Status",
              autoCompleteStatuses
            )}
            {renderAutocomplete(
              "marketingDesigner",
              "Designer",
              filterOptions.productDesignerMkts
            )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "seriesName",
                "Series Name",
                filterOptions.seriesNames
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "pdFamily",
                "PD Family",
                filterOptions.pdFamilyNames
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "category",
                "Category",
                filterOptions.productcategories
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "function",
                "Function",
                filterOptions.productFunctions
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "baseCode",
                "Base Code",
                filterOptions.baseCodes
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete("pdCode", "PD BaseCode", filterOptions.pdCodes)}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "stockCode",
                "Stock Code",
                filterOptions.stockCodes
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "primaryMaterial",
                "Primary Material",
                filterOptions.primaryMaterials
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "secondaryMaterial",
                "Secondary Material",
                filterOptions.secondaryMaterials
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "slIntroDate",
                "SL Intro Date",
                filterOptions.productIntroDatess,
                (option) => moment(option).format(DATE_FORMAT)
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderMultiSelectAutocomplete(
                "tags",
                "Tags",
                filterOptions.projectTags
              )}
            {viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] &&
              renderAutocomplete(
                "developmentType",
                "Development Type",
                PRODUCT_TYPE_DROPDOWN_OPTIONS
              )}
            {viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] &&
              renderAutocomplete(
                "vendor",
                "Vendor",
                filterOptions.productSuppliers
              )}
            {viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] &&
              renderAutocomplete(
                "quoteDate",
                "Quote Date",
                filterOptions.quoteDates,
                (option) => moment(option).format(DATE_FORMAT)
              )}
            {viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] &&
              renderAutocomplete(
                "quoteApprovalType",
                "Quote Approval Type",
                QUOTE_APPROVAL_TYPE_DROPDOWN_OPTIONS
              )}
            {viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              renderAutocomplete(
                "assignment",
                "Assignment",
                filterOptions.assignments
              )}
            {viewMode !== PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] &&
              <TextField
                sx={filterInputStyle}
                className="hide-input-arrow"
                label="Search Product Id"
                variant="outlined"
                inputProps={{ type: "number" }}
                value={
                  renderFilterOptionsSelected("projectCode")
                }
                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                onChange={(event) =>
                  handleFilterChange("projectCode", (event.target.value).trim())
                }
              />}
            <Button
              variant="contained"
              style={{ margin: "8px 5px", width: "135px", maxHeight: "37px" }}
              onClick={() => onApplyFilter(
                viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] ?
                  productListFilterValuesSelected : viewMode === PermissionsFriendlyNames[Permissions.ViewQuotes] ?
                    quoteListFilterValuesSelected : viewMode === PermissionsFriendlyNames[Permissions.ProjectOverviewDashboard] ?
                      projectOverviewDashboardFilterValuesSelected : dashboardFilterValuesSelected)
              }
            >
              Search
            </Button>
            <Button
              variant="outlined"
              style={{ margin: "8px 5px", width: "135px", maxHeight: "37px" }}
              onClick={handleResetFilterClick}
            >
              Reset
            </Button>
           {/*  {viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] &&
              renderCheckbox(
                "hideReleasedToInriver",
                "Hide Released To Inriver",
                (e) => HandleCheckboxChange("hideReleasedToInriver",e.target.checked)
              )
            }
            { viewMode === PermissionsFriendlyNames[Permissions.ViewProducts] &&
              renderCheckbox(
                "hideArchivedDevelopment",
                "Hide Archived Development",
                (e) => HandleCheckboxChange("hideArchivedDevelopment",e.target.checked)
              )
            } */}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};
export default Filters;
