import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedFilters } from "../models/Items/ItemListRequest";
import { ProjectDashboardRequest } from "../models/Items/ProjectDashboardRequest";

type FiltersState = {
  selectedFilterOptions: any;
  selectedFilterValues: SelectedFilters;
}

interface projectDashboardState {
  projectChartRequest: ProjectDashboardRequest;
  activeProjectListFilters: FiltersState;
};

const initialProjectDashboardRequest = {
  isPrjCodeWise: true
};

const initialSelectedFilters: SelectedFilters = {};

const initialState: projectDashboardState = {
  projectChartRequest: initialProjectDashboardRequest,
  activeProjectListFilters: {
    selectedFilterOptions: {},
    selectedFilterValues: initialSelectedFilters,
  },
};

const projectDashboard = createSlice({
  name: 'projectDashboard',
  initialState,
  reducers: {
    updateProjectChartRequest(state, action: PayloadAction<ProjectDashboardRequest>) {
      state.projectChartRequest = action.payload;
    },
    updateActiveProjectListSelectedFilterOptions(state, action: PayloadAction<any>) {
      state.activeProjectListFilters.selectedFilterOptions = action.payload;
    },
    updateActiveProjectListSelectedFilterValues(state, action: PayloadAction<SelectedFilters>) {
      state.activeProjectListFilters.selectedFilterValues = action.payload;
    },
  },
});

export const { updateProjectChartRequest, updateActiveProjectListSelectedFilterOptions, updateActiveProjectListSelectedFilterValues } = projectDashboard.actions;

export default projectDashboard.reducer;